import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "./img/leaf.png";
import { LanguageContext } from "../controller/LanguageContext";
import { Switch } from "@mui/material";
import { useContext } from "react";
import en from "./img/en.png";
import cn from "./img/cn.png";
import leaf from "./img/leaf.png";

function Navigation() {
	const { isEn, changeLanguage } = useContext(LanguageContext);

	return (
		<div className="container-fluid navigation">
			<Navbar collapseOnSelect expand="lg" variant="dark">
				<Navbar.Brand className="links">
					<Link to="/" style={{ textDecoration: "none" }}>
						<img src={leaf} />
						<span id="brand" className="m-0">
							VOICES Education
						</span>
					</Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto">
						<Link className="links" to="/">
							{isEn ? "首页" : "Home"}
						</Link>
						<Link className="links" to="/about">
							{isEn ? "关于我们" : "About"}
						</Link>
						<Link className="links" to="/packages">
							{isEn ? "产品" : "Packages"}
						</Link>
						<Link className="links" to="/contact">
							{isEn ? "联系我们" : "Contact"}
						</Link>
					</Nav>
					<div className="toggleLanguage">
						<Switch onChange={changeLanguage}></Switch>
						<img src={isEn ? cn : en} style={{ width: "35px" }} />
					</div>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

export default Navigation;
