import "./App.css";
import Nav from "./components/Navigation";
import Home from "./components/Home";
import Package from "./components/Package";
import About from "./components/About";
import Vip from "./components/Vip";
import Footer from "./Footer";
import Contact from "./components/Contact";
import { Route, Routes } from "react-router-dom";
import { LanguageContextProvider } from "./controller/LanguageContext";

function App() {
	return (
		<div className="App">
			<LanguageContextProvider>
				<Nav />
				<div className="contents">
					<Routes>
						<Route path="/" element={<Home />}></Route>
						<Route path="/packages" element={<Package />}></Route>
						<Route path="/packages/vip" element={<Vip />}></Route>
						<Route path="/about" element={<About />}></Route>
						<Route path="/contact" element={<Contact />}></Route>
					</Routes>
				</div>
				<Footer></Footer>
			</LanguageContextProvider>
		</div>
	);
}

export default App;
