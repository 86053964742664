import React from 'react'
// logo temp

export default function CaseStudyItem(props) {
    const {name, school, review, img } = props
  return (
    <div className='caseStudyItemContainer'>
        <div className='caseStudyItem-section-one'>
            <img src={img} style={{width: '64px', height:'64px' }}/>
            <div>
                <h3>{name}</h3>
                <p>{school}</p>
            </div>
        </div>
        <div className='caseStudyItem-section-two'>{review}</div>
    </div>
  )
}
