import React, { useContext } from "react";
import data from "../data";
import { LanguageContext } from "../controller/LanguageContext";

export default function Vip() {
	const vip = data[0].vip;
	const { isEn } = useContext(LanguageContext);

	return (
		<div className="fadeIn">
			{vip.map((pkg) => {
				return (
					<div className="vip-page" key={pkg.misc}>
						<div className="vipContainer-left" style={{ overflow: 'auto' }}>
							<h3 style={{ textAlign: "left" }}>
								{isEn ? pkg.titleCn : pkg.title}
							</h3>
							<ul>
								{pkg.descriptions.map((el) => {
									return <li key={el.text}>{isEn ? el.textCn : el.text}</li>;
								})}
							</ul>
						</div>
						<div className="vipContainer-right">
							<p>Package starts at: $TBD</p>
							<p>{isEn ? pkg.miscCn : pkg.misc}</p>
						</div>
					</div>
				);
			})}
		</div>
	);
}
