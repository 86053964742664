import { React, useContext } from "react";
import { Link } from "react-router-dom";
import PackageCard from "./PackageCard";
import CaseStudy from "./CaseStudy";
import data from "../data";
import { Button } from "react-bootstrap";
import { LanguageContext } from "../controller/LanguageContext";
import Swiper from "./Swiper";

export default function Package() {
	const packages = data[0].packages;
	const caseStudy = data[0].caseStudy;
	const { isEn } = useContext(LanguageContext);

	return (
		<div className="fadeIn">
			<div className="package-section-one">
				<div className="title">
					<h3>Package and Pricing</h3>
				</div>
				<div className="vipConsuting">
					<h4>VIP CONSULTING</h4>
					<h4>
						We welcome you to take a closer look and to speak with one our
						specialized counselors
					</h4>
					<Link
						style={{ color: "white", textDecoration: "none" }}
						to="/packages/vip"
					>
						<Button className=" vipInquireButton">
							<h4>INQUIRE FURTHER</h4>
						</Button>
					</Link>
				</div>
				<div className="package-section-two">
					{packages.map((pkg) => {
						return (
							<PackageCard
								key={pkg.title}
								title={isEn ? pkg.titleCn : pkg.title}
								content={isEn ? pkg.contentCn : pkg.content}
								subTitle={isEn ? pkg.subTitleCn : pkg.subTitle}
								services={isEn ? pkg.servicesCn : pkg.services}
							/>
						);
					})}
				</div>
			</div>
			<div className="package-section-three">
				<Swiper caseStudy={caseStudy} />
			</div>
		</div>
	);
}
