import React, { useContext } from "react";
import data from "../data";
import { LanguageContext } from "../controller/LanguageContext";

export default function SecondTeam() {
	const teamTwo = data[0].teamsTwo;
	const { isEn } = useContext(LanguageContext);

	return (
		<div className="teamTwoContainer d-flex">
			{teamTwo.map((team, index) => {
				return (
					<div
						key={index}
						className="teamTwo"
						style={{
							marginBottom: "25px",
							margin: "15px 15px",
						}}
					>
						<img src={team.img} />
						<p>{team.name}</p>
						<p>{isEn ? team.occupationCn : team.occupation}</p>
						<ul>
							{team.content.map((ct, index) => {
								return <li key={index}>{isEn ? ct.detailCn : ct.detail}</li>;
							})}
						</ul>
					</div>
				);
			})}
		</div>
	);
}
