import React, { useContext } from "react";
import Team from "./Team";
import data from "../data";
import { LanguageContext } from "../controller/LanguageContext";
import SecondTeam from "./SecondTeam";

export default function About() {
	const teams = data[0].teams;
	const { isEn } = useContext(LanguageContext);

	return (
		<div className="about-section-one fadeIn">
			{teams.map((team) => {
				return (
					<div
						style={{
							boxShadow: "5px 5px 20px -2px rgba(0,0,0,0.64)",
							WebkitBoxShadow: "5px 5px 20px -2px rgba(0,0,0,0.64)",
							MozBoxShadow: "5px 4px 7px -4px rgba(0, 0, 0, 0.50)",
							color: '#C49090',
						}}
						key={team.name}
						className="teamOne"
					>
						<Team
							img={team.img}
							name={team.name}
							content={team.content}
							backgroundColor={team.backgroundColor}
							textColor={team.textColor}
						/>
						<h3 id="teamQuote" style={{ color: "#d15858" }}>
							{isEn ? (team.quoteCn.length > 2 ? `“` + team.quoteCn + `”` : null) : team.quote.length > 2 ? `"` + team.quote + `"` : null}
						</h3>
					</div>
				);
			})}
			<div id="about-section-two">
				<SecondTeam />
			</div>
		</div>
	);
}
