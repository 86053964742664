import React, { useContext } from "react";
import { LanguageContext } from "../controller/LanguageContext";

export default function JourneyBox(props) {
	const { isEn } = useContext(LanguageContext);

	return (
		<div className="journeyBox">
			<img src={props.img} />
			<div id="journeyTextContainer">
				<p>
					<strong>{props.title}</strong>
				</p>
				<p style={{ textAlign: "left" }}>{props.description}</p>
			</div>
		</div>
	);
}
