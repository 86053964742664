import React, { Component, useContext, useEffect } from "react";
import JourneyBox from "./JourneyBox";
import data from "../data";
import shanghai from "./img/shanghai.png";
import columbiaUniversity from "./img/columbiaUniversity.jpg";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { LanguageContext } from "../controller/LanguageContext";
import axios from "axios";

export default function Home() {
	const ourMission = data[0].ourMission;
	const personalConsultation = data[0].personalConsultation;
	const journey = data[0].journey;

	const { isEn } = useContext(LanguageContext);
	useEffect(() => {
		axios.get('https://api.konnect5.com/').then(response => {
			console.log(response)
		})
	}, [])
	return (
		<div className="fadeIn">
			<div className="home-section-one">
				{isEn ? (
					<h3 className="cn">我们的使命</h3>
				) : (
					<h3 className="en">OUR MISSION</h3>
				)}

				<div>
					<span>
						{
							!isEn ?
								<p style={{ textAlign: 'left', padding: '2rem', color: 'white', lineHeight: '20pt', letterSpacing: '1pt' }}>{ourMission.content}</p>
								: <p style={{ textAlign: 'left', padding: '2rem', color: 'white', lineHeight: '20pt', letterSpacing: '2pt' }}>{ourMission.contentCn}</p>
						}
					</span>
					<span id="home-section-one-right">
						{isEn ? (
							<h4>
								每个学生都有自己独一无二的人生之旅；而我们的工作，就是让他们明白自己的生活拥有无数的可能。
							</h4>
						) : (
							<h4>
								Each student has their own unique journey, it is important for us to show them the innumerable possibilities their own life holds.
							</h4>
						)}
					</span>
				</div>
				<div></div>
			</div>
			<div className="home-section-two">
				<img src={shanghai} alt="homeimg" />
				<div className="home-section-three">
					{isEn ? (
						<h3>
							“致力于培养学术好奇心，采用以学生为中心的方法，植根于同理心和最佳教育实践，以促进高等教育及其他领域的旅程。”
						</h3>
					) : (
						<h3>
							"Dedicated to fostering academic curiosity for a student-centered approach rooted in empathy and best education practices to facilitate the journey toward higher education and beyond."
						</h3>
					)}
				</div>
			</div>
			<div className="home-section-four">
				<div>
					<h3>SCHEDULE YOUR PERSONAL CONSULTATION</h3>
					<p>
						In our 45 minute non-committal call, we can discuss and explore your
						college bound goals.
					</p>

					<Link to="/contact" style={{ textDecoration: "none" }}>
						<Button
							className="buttons"
							style={{
								marginTop: "100px",
								backgroundColor: "white",
								color: "#C49090",
							}}
						>
							LEARN MORE
						</Button>
					</Link>
				</div>
				<div className="beginJourneyFlex">
					<span
						className="beginJourneyImgBlock"
						style={{ borderRight: "1px solid white" }}
					>
						<img src={columbiaUniversity} alt="columnbia" />
					</span>
					<span className="consultationUL">
						<p>
							During our non-committal consultation we can explore your college
							bound goals.
						</p>
						<ul>
							{personalConsultation.map((el) => {
								return (
									<li key={el.content}>
										<p>{el.content}</p>
									</li>
								);
							})}
						</ul>
					</span>
				</div>
			</div>
			<div className="home-section-five">
				{isEn ? <h3>开始你的旅程</h3> : <h3>YOUR JOURNEY STARTS HERE</h3>}
				<div className="journeyBoxContainer">
					{journey.map((el) => {
						return (
							<JourneyBox
								key={el.title}
								img={el.img}
								title={el.title}
								description={isEn ? el.descriptionCn : el.description}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
