import consultation from "./components/img/consultation.png";
import chooseprogram from "./components/img/chooseprogram.png";
import collegebound from "./components/img/collegebound.png";
import startlesson from "./components/img/startlesson.png";

//teams photos
import kening from "./components/img/khpro.png";
import meci from "./components/img/meci.png";
import abby from "./components/img/abby.png";
import leaf from "./components/img/leaf.png";
import derrick from "./components/img/derick.png";
import liz from "./components/img/liz.png";
import blackey from "./components/img/blackey.png";
import yang from "./components/img/yang.jpg";
import ross from "./components/img/ross.jpg"

export const data = [
	{
		ourMission: {
			contentCn: "Voices Education相信每个学生都有独特的旅程,通过自我探索,早期规划和最终的论文。我们的重点是通过设定个人目标来建立一个人的自信心。我们所有学生的目标是获得大学准备技能,探索新的机会,并获得最佳的大学就位结果。我们专注于倾听每个学生的需求,通过创建一个基于导师的大学录取计划,而不是一个仅专注于排名的计划。我们为学生提供独特的教育体验,以便他们通过早期探索常见的申请主题更好地发现自己想成为的人。我们相信每个学生都应该有一个严格的学术计划,其中包括文化融入和个人发展的机会。我们相信培养领导技能,团队合作,沟通和创造力技能。Voices Education致力于仅与我们的学生合作,并限制每年招生人数,以确保与我们的学生的每个会话的质量。Voices Education不使用第三方和兼职员工代替我们的会话。我们所有的顾问都在我们的网站上列出,以确保您的体验质量。Voices Education坚决尊重客户的隐私,并不会在没有明确许可的情况下,将任何记录与个人身份识别符一起进行广告。我们相信这对我们许多学生来说是个人的旅程。",
			content: "At Voices Education, we believe that each student has a unique journey through self-exploration, early planning, and their final essay. Our focus is to build one's confidence by setting personal goals. The aim for all our students is to gain college readiness skills, explore new opportunities, and receive the best college placement outcomes. We focus on listening to the needs of each of our students by creating a mentorship-based program for college admission instead of one focused solely on ranking. We offer students a unique educational experience so they can better discover who they aspire to become through an early exploration of common application topics. We believe each and every student should have a rigorous academic plan which includes opportunities for cultural immersion and personal development. We believe in fostering leadership skills, teamwork, communication, and creative skills. Voices Education is committed to working with our students in-house only and limits the number of intake per year to ensure the quality of each session with our students. Voices Education does not use third-party and part-time employees as stand-ins for our sessions. All our counselors are listed through our website to ensure the quality of your experience. Voices Education believes strongly in the privacy of our clients and will not advertise any records with or without personal identifiers unless with explicit permission. We believe that this journey is personal for many of our students."
		},
		personalConsultation: [
			{
				content: "Gain an understanding of your own goals for personal growth",
			},
			{ content: "What you are looking for in your higher education journey" },
			{
				content:
					"Get a clear image of where you stand and where you are headed",
			},
			{ content: "Develop a structured timeline for each stage of admissions" },
			{ content: "Gain an understanding of what the schools are looking for" },
		],
		journey: [
			{
				img: consultation,
				title: "CONSULTION",
				descriptionCn:
					"了解我们以学生为中心的大学准备方法。这些计划旨在支持学生踏上自己的教育之旅。安排 45 分钟的通话将有助于您更好地了解我们如何提供帮助。",
				description:
					"Learn about our student-centered approach toward college readiness. The programs are designed to support students as they embark on their own educational journeys. Scheduling  a 45- minute call will help give you a better idea how we can help.",
			},
			{
				img: chooseprogram,
				title: "CHOOSE A PROGRAM",
				descriptionCn:
					"我们为寻求不同参与程度的学生提供各种课程。请随意探索我们的套餐，看看哪一款最适合您的需求。",

				description:
					"We offer a variety of programs for students looking for different levels of engagement. Please feel free to explore our packages and see which one would be the best fit for your needs.",
			},
			{
				img: collegebound,
				title: "START YOUR LESSON PLAN",
				descriptionCn:
					"我们提供专家咨询和专业规划。我们在定制学生的课程活动时采取整体方法。我们为所有学生提供有针对性的大学选择策略，重点是个人论文写作。",

				description:
					"We provide expert counseling and professional planning. We take a holistic approach when tailoring student's curricular activities. We provide all of our students with targeted college selection strategies with an emphasis on personal essay writing.",
			},
			{
				img: startlesson,
				title: "COLLEGE BOUND",
				descriptionCn:
					"我们的目标是确保我们的每个学生都对自己选择的学校感到满意。除了我们的申请服务外，我们还帮助学生办理延期、附上推荐信和一份免费的转学申请。对于我们的国际学生，我们提供签证申请流程的详细介绍。",

				description:
					"Our goal is ensure that each and everyone of our students is comfortable with their school of choice. In addition to our application services, we help students with deferrals, with list letters and one free transfer application. For our international students we offer a detailed walk through of the visa application process.",
			},
		],
		packages: [
			{
				titleCn: "本科生申请副标题",
				title: "Undergrad Application",
				subTitleCn: "仅限 12 年级",
				subTitle: "12 grade only",
				contentCn: [
					{
						detail: "根据学生的兴趣和偏好制定并最终确定大学名单。",
					},
					{
						detail: "根据每个机构的特点和使命制定申请每个大学的策略。",
					},
					{
						detail:
							"集思广益，撰写各种论文，包括个人陈述、学术兴趣论文和其他类似主题。",
					},
					{
						detail:
							"帮助学生精心叙述，向招生人员传达他们的身份并向招生人员强调他们的优势。",
					},
					{
						detail:
							"我们提供第二位读者来审查学生最终的申请，以确保一致性、清晰度和创造力。",
					},
					{
						detail: "所有工作都有 48 小时周转时间。",
					},
				],
				content: [
					{
						detail:
							"Develop and finalize a list of colleges based on students’ interests and preferences.",
					},
					{
						detail:
							"Devise strategies for applying to each college based on each institution's characteristics and mission.",
					},
					{
						detail:
							"Brainstorm ideas for various essays, including personal statements, academic interest essays, and other similar topics.",
					},
					{
						detail:
							"Help students craft narratives that convey who they are and highlight their strengths to admissions officers.",
					},
					{
						detail:
							"We offer a second reader to review student's finalized applications to ensure consistency, clarity, and creativity.",
					},
					{
						detail: "All work come with a 48 hour turnaround time.",
					},
				],
				servicesCn: [
					{
						title: "套餐1",
						name: "",
						description: "及时提交一份EA/ED申请,最多20小时工作时间。 ",
						price: "3,000",
					},
					{
						title: "套餐2",
						name: "",
						description:
							"10份学校申请套餐,仅限美国和加拿大学校,不限工作时间。",
						price: "10,000",
					},
					{
						title: "套餐3",
						name: "",
						description:
							"无限学校申请,包括英国、香港、新加坡和加拿大学校的国际申请,包括签证咨询和无限工作时间。",
						price: "15,000",
					},
				],

				services: [
					{
						title: "Package 1",
						name: "",
						description:
							"One application in time for EA/ED, up to 20 hours of work included.",
						price: "3,000",
					},
					{
						title: "Package 2",
						name: "",
						description:
							"10 School Application package for US and Canada schools only, unlimited working hours.",
						price: "10,000",
					},
					{
						title: "Package 3",
						name: "",
						description:
							"Unlimited School Application, inclusive of international application for UK, HK, Singapore and Canada schools, inclusive of Visa consulting and unlimited working hours.",
						price: "15,000",
					},
				],
			},
			{
				titleCn: "研究生院申请",
				title: "Grad School",
				subTitleCn: "",
				subTitle: "",
				contentCn: [
					{ detail: "通过电子邮件、电话和视频会议提供一对一建议。" },
					{ detail: "选校指导,规划暑期实习和研究。" },
					{ detail: "集思广益、概述和无限编辑一份目的陈述。" },
					{ detail: "推荐信指导。" },
					{ detail: "模拟面试。" },
					{
						detail:
							"帮助学生精心叙述，向招生人员传达他们的身份并向招生人员强调他们的优势。",
					},
					{
						detail:
							"我们提供第二位读者来审查学生最终的申请，以确保一致性、清晰度和创造力。",
					},
					{ detail: "候补名单建议和候补名单信件编辑。" },
					{ detail: "决策咨询。" },
					{ detail: "所有工作都有 48 小时周转时间。" },
				],
				content: [
					{
						detail:
							"One-on-one advising via email, phone and video conferencing.",
					},
					{
						detail:
							"School selection guidance, planning your summers for internship and research.",
					},
					{
						detail:
							"Brainstorming, outlining and unlimited editing of one statement of purpose.",
					},
					{ detail: "Letter of recommendation guidance" },
					{ detail: "Mock Interview" },
					{
						detail:
							"Help students craft narratives that convey who they are and highlight their strengths to admissions officers.",
					},
					{
						detail:
							"We offer a second reader to review students’ finalized applications to ensure consistency, clarity, and creativity.",
					},
					{ detail: "Waitlist advice and waitlist letter editing." },
					{ detail: "Decision counseling." },
					{ detail: "All work come with a 48 hour turnaround time." },
				],
				servicesCn: [
					{
						title: "套餐 1",
						name: "仅研究生院",
						description: "限 10 名研究生院申请,无工作时间限制。",
						price: "10,000",
					},
					{
						title: "套餐 2",
						name: "Grad学校和工作申请 ",
						description:
							"限 10 份研究生院申请、简历和求职信写作、100 份个人业务以及 3 个模拟设计、无限编辑以及 2 年后重新申请工作/研究生的机会,具体取决于首先选择的路径。",
						price: "10,000",
					},
				],
				services: [
					{
						title: "Package 1",
						name: "Grad School only",
						description:
							"Up to 10 Grad School application, unlimited working hours.",
						price: "10,000",
					},
					{
						title: "Package 2",
						name: "Grad School and Working Application",
						description:
							"Up to 10 Grad School application, Resume and Cover Letter writing,100 personal business with 3 mock designs, unlimited edits and an opportunity to reapply for work/grad after 2 years depending which path was chosen first.",
						price: "15,000",
					},
				],
			},
			{
				titleCn: "考试准备和其他服务",
				title: "Test Prep & Others",
				subTitle: "",
				contentCn: [
					{
						detail:
							"提供有关申请大学所需标准化考试的信息和资源，包括 SAT、ACT、AP、IB 和 TOEFL。",
					},
					{
						detail: "我们提供适当的评估测试（SAT 和 ACT）供您在方便时参加。",
					},
					{
						detail:
							"审核后，我们将提供个性化计划，包括参加哪些考试、何时准备以及达到目标分数的有效时间表。",
					},
					{
						detail:
							"我们可以将学生与精英考试准备合作伙伴联系起来，以在线或离线方式进行适当的准备。",
					},
				],
				content: [
					{
						detail:
							"Provide information and resources regarding standardized tests needed for applying to colleges, including SAT, ACT, AP, IB and TOEFL.",
					},
					{
						detail:
							"We offer the appropriate assessment tests (SAT and ACT) to be taken at your convenience.",
					},
					{
						detail:
							"After review we will offer a personalized plan for which exams to take, when to do prep, and an efficient timeline to reach target scores.",
					},
					{
						detail:
							"We can link students to elite test prep partners for appropriate preparation, either online or offline.",
					},
				],
				servicesCn: [
					{
						title: "大学转学",
						name: "",
						description:
							"我们将与您一起重做您的个人论文。帮助整理您的申请包。在面试过程中为您提供支持。我们提供 1 对 1 辅导，所有提交的作业均在 48 小时内完成。包括10小时的服务。						",
						price: "2,000",
					},
					{
						title: "Resume Update",
						name: "",
						description:
							"我们将与您一起完成和编辑您的简历，并帮助您定制求职信。包括一套 100 张个性化名片，其中有 3 种模拟设计可供选择。包括10小时的服务。",
						price: "2,000",
					},
					{
						title: "研究生院重新申请",
						name: "",
						description:
							"作为重新申请者，使用研究生院招生咨询服务确实可以增强您的信心，并帮助您在尝试失败后获得录取。我们将与您一起重做您的论文，并为您的个人故事和品牌进行另一场头脑风暴会议。我们为所有提交的作品提供第二编辑。包括15小时的服务。",
						price: "2,000",
					},
					{
						title: "其他服务",
						name: "",
						description: "提供一对一按小时服务，每人 $450 美元。",
						price: "450",
					},
				],
				services: [
					{
						title: "College Transfer",
						name: "",
						description:
							"We will work with you to redo your personal essay. Help put together your application package. Support you during your interview process. We offer 1 on 1 tutoring with 48 hour turn around on all work submitted. Inclusive of 10 hours of service.",
						price: "2,000",
					},
					{
						title: "Resume Update",
						name: "",
						description:
							"We will work with you to finish and edit your resume and help you tailor your cover letter. Include a personalized set of 100 business cards with 3 mock designs to choose from. Inclusive of 10 hours of service.",
						price: "2,000",
					},
					{
						title: "Grad School Reapplication",
						name: "",
						description:
							"We will work with you to finish and edit your resume and help you tailor your cover letter. Include a personalized set of 100 business cards with 3 mock designs to choose from. Inclusive of 10 hours of service.",
						price: "2,000",
					},
					{
						title: "Hourly Rate",
						name: "",
						description: "hourly service can be purchased at 450 hr.",
						price: "450",
					},
				],
			},
		],

		teams: [
			{
				name: "KENING HUA",
				img: kening,
				quoteCn: "",
				quote:
					"Each student has a unique journey. It is important for each one of them to see the possibilities their own life holds.",
				backgroundColor: "#C49090",
				textColor: "white",
				content: [
					{
						detailCn:
							"在曼荷莲学院获得政治和哲学学士学位，在哥伦比亚大学获得管理和筹款硕士学位。",
						detail:
							"Kening received her B.A in Politics and Philosophy from Mount Holyoke College, and her M.S in Management and Fundraising from Columbia University.",
					},
					{
						detailCn:
							"她与学生一起工作了 10 多年，专门从事自我发现的独立项目。她是一名独立教育顾问，曾与中国、香港、新加坡、加拿大和美国的学生合作。",
						detail:
							"She has worked with students for over 10 years and specializes in independent projects for self discovery. She is an Independent Educational Consultant who has worked with students in China, Hong Kong, Singapore, Canada and the US.",
					},
					{
						detailCn:
							"她致力于创造一个支持性空间，以满足每个学生的需求，帮助他们培养热情并定义他们的世界观。",
						detail:
							"She is committed to creating a supportive space to meet the needs of each of her students, to help them develop their passion and define their world views.",
					},
					{
						detailCn: "她与学生相处的过程突出并强调了指导和辅导过程。",
						detail:
							"Her process with her students highlights and emphasizes the mentoring and coaching process.",
					},
					{
						detailCn:
							"她在教育和职业生涯中与青少年和年轻人合作​​，制定一项计划，建立植根于个人目标、大学准备技能和拥抱新机会的开放性的信心。",
						detail:
							"She works with teens and young adults with her education and career journey to create a plan which builds one's confidence rooted in personal goals, college readiness skills and an openness to embrace new opportunities.",
					},
					{
						detailCn: "她目前担任长岛曼荷莲学院俱乐部的年轻校友代表。",
						detail: "She currently serves as the Young Alumnae Representative for the Mount Holyoke College Club of Long Island.",
					},
				],
			},
			{
				name: "JOHN MECI",
				img: meci,
				quoteCn: "",
				quote:
					"“Mentorship should be built on the foundation of helping one's student find their own voice.”",
				backgroundColor: "white",
				textColor: "#C49090",
				content: [
					{
						detailCn:
							"John一直是一位充满热情的音乐家,并在高中时参加了朱利亚德的年轻作曲家计划。",
						detail:
							"John has always been a passionate musician, and was part of Julliard's young composers program in High School.",
					},
					{
						detailCn:
							"他在西北大学获得音乐作曲和音乐学学士学位，在加州大学伯克利分校获得音乐史博士学位。",
						detail:
							"He received his B.A in Music Composition and Musicology from Northwestern, and his PhD in Music History from UC Berkeley.",
					},
					{
						detailCn: "他与学生一起工作了 6 年多，专门从事音乐专业的独立项目。",
						detail:
							"He has worked with students for over 6 years and specializes in independent projects with a music specialization.",
					},
					{
						detailCn:
							"他在加州大学伯克利分校期间与学生一起工作，目前在旧金山交响乐团工作。",
						detail:
							"He has worked with students during his stay at UC Berkeley and is currently working at the San Francisco Symphony.",
					},
					{
						detailCn: "他与学生相处的过程突出并强调。",
						detail:
							"His process with students highlights and emphasizes help student find their own passion on their build journey while helping to build their confidence as performers.",
					},
					{
						detailCn: "John 提供专业的试镜和录音指导，帮助申请者展现其天赋才华。",
						detail: "John offer special insight and guidance for auditions and work recording, striving to best showcase the applicants natural talent.",
					},
				],
			},
			{
				name: "ABBY TANG",
				img: abby,
				quoteCn: "除了你自己之外，艺术自由没有任何限制。",
				quote:
					"There are no limitations to artistic freedom, other than oneself.",
				backgroundColor: "#C49090",
				textColor: "white",
				content: [
					{
						detailCn:
							"Abby 在帕森斯设计学院获得建筑设计学士学位，在普拉特学院获得建筑硕士学位。",
						detail:
							"Abby received her BFA in Architectural Design from Parsons School of Design and her M.Arch from Pratt Institute.",
					},
					{
						detailCn:
							"她与学生一起寻找他们的艺术家身份，并帮助他们从世俗的角度培养他们的发现。",
						detail:
							"She works with students to find their artist identities, and helps nurture their findings in a worldly perspective.",
					},
					{
						detailCn:
							"她专注于通过融合哲学和艺术的跨学科行动，从而形成一种内省的创造和自我发现的方法。",
						detail:
							"She specializes in cross-disciplinary action by merging philosophy and art, leading to an introspective approach towards creating and self discovery.",
					},
					{
						detailCn:
							"她的目标是通过对未来自我的个性化想象，为学生奠定基础，通过热情强调自信。",
						detail:
							"Her goal is to build a foundation for her students through individualized visualization of their future selves, highlighting confidence through passion.",
					},
					{
						detailCn:
							"她的方法是通过批判性思维、制作和创造的过程引发自我反思。",
						detail:
							"Her approach is to provoke self reflections through the process of critical thinking, making and creating.",
					},
					{
						detailCn:
							"艾比专注的教学方式深深植根于她自己的自我发现，为纽约市各地的住宅和商业项目的建设建立了自己的设计实践。",
						detail:
							"Abby’s dedicated ways of teaching is deep-rooted from her own self discovery, establishing her own design practice for building residential and commercial projects throughout NYC.",
					},
				],
			},
			{
				name: "YANG LI",
				img: yang,
				quoteCn: "",
				quote:
					"There are no limitations to artistic freedom, other than oneself.",
				backgroundColor: "#C49090",
				textColor: "white",
				content: [
					{
						detailCn: "杨先生在哈佛大学获得政府国际关系学士学位。",
						detail:
							"Yang is a National Merit Scholarship Recipient, Intel Science Talent Search Finalist and Captain of Harvard Model UN Team.",
					},
					{
						detailCn:
							"杨是国家优秀奖学金获得者、英特尔科学人才搜索决赛入围者和哈佛模拟联合国团队队长。",
						detail: "Yang received his BA at Havard in Government.",
					},
					{
						detailCn:
							"他目前是位于上海的独家考试准备中心 Blueprint 的联合创始人。",
						detail:
							"He is currently the co-found of Blueprint, an exclusive test prep center based in Shanghai.",
					},
					{
						detailCn: "杨先生在哈佛大学的毕业论文荣获哈佛美国政治研究中心奖。",
						detail:
							"Yang's Senior Thesis at Havard won Harvard Center for American Political Studies award.",
					},
					{
						detailCn:
							"他本人在高中时 LSAT 成绩为 176 分，真实 SAT 成绩为 1600 分。",
						detail:
							"He, himself scored  176 on his LSAT and 1600 on the real SAT in high school.",
					},
					{
						detailCn: "杨先生拥有超过8年的 SAT/ACT/ELA/AP 教学经验。",
						detail:
							"Yang has over 8 years of  SAT/ACT/ELA/ AP teaching experience.",
					},
					{
						detailCn: "杨先生指导过超过90名SAT学生得分2350+或1550+。",
						detail: "Over 90 of SAT student scored  2350+ or 1550+.",
					},
					{
						detailCn: "在过去的3年里,他有超过20名学生取得了ACT/SAT满分。",
						detail:
							"In the past 3 years he has had over 20 student who had a perfect ACT/SAT score.",
					},
				],
			},
			{
				name: "ROSS W. GRAY",
				img: ross,
				quoteCn: "",
				quote: "",
				backgroundColor: "#C49090",
				textColor: "white",
				content: [
					{
						detailCn: "多伦多大学国际关系硕士学位。",
						detail: "University of Toronto, International Relations (MA).",
					},
					{
						detailCn: "曾经在7个大学和机构游学和任职过。",
						detail: "Studied and researched at 7 universities globally.",
					},
					{
						detailCn: "14年以上教育教学经验,曾任启德教育专家多年。",
						detail: "14 years of teaching experience, Former Curriculum Designer, EIC/New Pathway.",
					},
					{
						detailCn: "负责开发英语教学教材, 数量可观。",
						detail: "Responsible for the development of English language teaching materials.",
					},
					{
						detailCn: "专长教授阅读和文学, 如AP Lit, AP History等。",
						detail: "Expertise in reading and literature courses, such as AP Lit, AP History, AP Psychology, etc.",
					},
					{
						detailCn: "超过85位SAT学生获得1550分及以上成绩。",
						detail: "Over 85 SAT students scored 1550+.",
					},
					{
						detailCn: "超过110位ACT学生获得35分及以上成绩。",
						detail: "Over 110 ACT students scored 35+.",
					},
					{
						detailCn: "IB证书, 有资深的IB教学经验。",
						detail: "IB diploma holder and experienced in teaching IB courses.",
					},
					{
						detailCn: `开发开设的“学习的技巧和方法”课程广受中学生和家长 的欢迎。`,
						detail: `His "Study Skills" course is among the most demanded.`,
					},
					{
						detailCn: "知识渊博, 教学生动, 引人入胜, 是一位学者型的导师。",
						detail: "A scholarly instructor who is both lively and engaging in teaching.",
					},
				]
			}
		],
		teamsTwo: [
			{
				name: "DERRICK GOMAZ",
				img: derrick,
				quoteCn: "永远不要害怕你的梦想和抱负。胸怀大志,成就更多。",
				quote:
					"Never be afraid of your dreams and ambitions. Think big, achieve more.",
				backgroundColor: "white",
				textColor: "#C49090",
				occupation: "Interview and Public Speaking Coach",
				occupationCn: "面试和公开演讲教练",
				content: [
					{
						detailCn:
							"Derrick Gomez 是 Choate Rosemary Hall 的校友，并与耶鲁大学 YEI 研究员一起获得康奈尔大学硕士学位。",
						detail:
							"Derrick Gomez is an alumnus of Choate Rosemary Hall and received his M.S from Cornell University with a Yale University YEI fellow.",
					},
					{
						detailCn:
							"Derrick 是一位以结果为导向的企业家，在管理风险、领导跨职能团队以及提高跨行业战术价值方面经验丰富。",
						detail:
							"Derrick is a results-driven entrepreneur experienced in managing risk, leading cross-functional teams, and growing tactical value across industries.",
					},
					{
						detailCn: "他目前担任 Mark Edward Partners 的首席行政官。",
						detail:
							"He is currently the Chief Administrative Officer of Mark Edward Partners.",
					},
					{
						detailCn:
							"Derrick 帮助学生制作一份适合市场的全面简历，结合他自己的经验，他在模拟面试中非常高效，帮助学生成为强大而自信的候选人。",
						detail:
							"Derrick helps students develop a well rounded resume that is market ready, combined with his own experience, he is extremely efficient during mock interviews to help students come across as strong and confident candidates.",
					},
					{
						detailCn:
							"作为乔特和康奈尔大学的奖学金获得者，他了解脱颖而出的价值。他擅长公共演讲和专业礼仪培训。",
						detail:
							"As a scholarship recipient for Choate and Cornell, he understands the value of standing out. He specializes in public speaking and professional etiquette training.",
					},
					{
						detailCn:
							"他目前担任纽约乔特罗斯玛丽霍尔校友委员会联合主席以及雅各布理工学院-康奈尔学院跑道项目的行业合作伙伴和顾问。",
						detail:
							"He is currently serving as the Co-Chair of New York for Choate Rosemary Hall's Alumni Committee and Industry Partner and Advisor to the Runway Program at Jacobs Technion-Cornell Institute.",
					},
				],
			},
			{
				name: "Elizabeth Raffanello",
				img: liz,
				quoteCn: "",
				quote: "",
				backgroundColor: "#C49090",
				textColor: "white",
				occupation: "Wellness and College Readiness Coach",
				occupationCn: "健康和大学准备教练",
				content: [
					{
						detailCn:
							"伊丽莎白在塔夫茨大学获得了临床心理学学士学位。她拥有耶鲁大学社会和行为科学硕士学位，并拥有纽约州立大学奥尔巴尼分校临床心理学博士学位。",
						detail:
							"Elizabeth received her BA in Clinical Psychology from Tufts University.  Her MPH in Social and Behavioral Sciences from Yale, and holds a PhD in Clinical Psychology from SUNY Albany.",
					},
					{
						detailCn: "她是 3 个孩子的母亲，坚信应该帮助学生尽早建立独立意识。",
						detail:
							"She is a mother of 3 and believes strongly in helping students establish a sense of independence early on.",
					},
					{
						detailCn:
							"她的专长是大学准备和适应性应对行为，减少孤立以防止抑郁，并增加归属感和自我价值。",
						detail:
							"Her specialization is college readiness and adaptive coping behaviors, decreasing isolation for protection from depression, and increasing belongingness and self-worth. ",
					},
					{
						detailCn:
							"她不仅帮助学生适应跨文化，还帮助学生获得生活技能和健康习惯，帮助学生找到目标，以及动机性访谈。",
						detail:
							"She helps students not only adjust cross-culturally but also help students gain life skills and healthy habits, helping students find purpose, and motivational interviewing.",
					},
					{
						detailCn: "伊丽莎白目前担任杜克大学研究项目负责人。",
						detail:
							"Elizabeth is currently serving as the research program leader at Duke University.",
					},
				],
			},
			{
				name: "Blackie Yuan",
				img: blackey,
				quoteCn: "",
				quote: "",
				backgroundColor: "#C49090",
				textColor: "white",
				occupation: "Language Prep Specialist ",
				occupationCn: "语言准备专家",
				content: [
					{
						detailCn:
							"Blackie拥有英国纽卡斯尔大学硕士学位,拥有超过九年的教学经验。",
						detail:
							"Blackie has a Master's degree from Newcastle University in the UK and has over nine years of teaching experience.",
					},
					{
						detailCn: "他擅长托福和雅思语言考试,并拥有中国教师资格证。",
						detail:
							"He specializes in TOEFL  and IELTS language tests and is certified to teach in China. ",
					},
					{
						detailCn: "他举办活跃的小组学习课程,并对他的主题充满热情。",
						detail:
							"He runs lively group study sessions  and is passionate about his subject matter.  ",
					},
					{
						detailCn: "他的学生平均能够在计划后 6 个月内达到目标分数。",
						detail:
							"His students on average are able to reach their target score within 6  months of planning. ",
					},
					{
						detailCn:
							"Blackies 通过自我评估来接近这些学生,然后为他们的下一步制定详细的个人计划,确保每个学生有效地最大化他们的时间",
						detail:
							"Blackies approaches this students with a self assessment followed by a detailed personal plan for their next step, ensuring that each student maximizes their time efficiently ",
					},
					{
						detailCn: "Blackie 目前是中国备考公司 TOK 的创始合伙人。",
						detail:
							"Blackie is currently the founding partner of TOK, a China based test prep company.",
					},
				],
			},
		],
		caseStudy: [
			{
				img: leaf,
				name: "Case Study One",
				nameCn: "案例一",
				school: "Undergrad + Grad school application",
				reviewCn:
					"Candice 是我们的学生之一,我们从她在温州的高中一直跟踪到她在 UCSB 的本科生,并承诺帮助她进入研究生院。她带着通过社交媒体做出改变的个性化品牌故事进入加州大学圣巴巴拉分校。尽管她的头两年正处于新冠大流行期间,但我们还是要利用她的存在来创造性地打击新冠错误信息。我们共同创作了一个故事,考虑到她对社交媒体的热情以及她对公共卫生的承诺。结果,她 100% 进入了研究生院课程,包括西北大学、康奈尔大学、约翰霍普金斯大学和哥伦比亚大学的录取通知书。",
				review:
					"Candice is one of our students whom we had followed from her high school in Wenzhou, to her Undergrad in UCSB and have committed to help get into Grad school.  She got into UCSB with a personalized branded story of making a difference through social media.  Despite her first two years being amid the covid pandemic, we were to utilize her presence to creatively combate covid misinformation.  Together we crafted a story which took into consideration her passion for social media as well as her commitment for public health.  As a result she got into 100% of her Grad school programs including offers from Northwestern, Cornell, JHU and Columbia.",
			},
			{
				img: leaf,
				name: "Case Study Two",
				nameCn: "案例二",
				school: "Internectional independent studies project",
				reviewCn:
					"声音教育致力于创造适合学生需求和兴趣的体验。罗恩是一名立志主修东亚研究的学生。作为他独立项目的一部分,我们在中国黄冈（距武汉市区 1.5 小时车程的一个小村庄）跟踪了一位独立农民及其家人的进展两年。罗恩不仅有机会了解农民的出口流程,而且我们能够深入研究中国存在贫富差距的潜在原因。罗恩能够与村民充分互动,并完善他的中文口语能力。",
				review:
					"Voices Education is committed to creating experiences which are tailored to the needs and interests and interests of our students.  Ron was a student aspiring to major in East Asian Studies. As part of his independent project, we followed the progress of an independent farmer and her family for 2 years in Huanggang, China, a small village 1.5 hours away from Wuhan proper.  Not only did Ron get the opportunity to learn about the export process for farmers, but we were able to do an in depth study of potential reasons for the wealth gap which exists in China.  Ron was able to become fully engaged with the villagers as well as perfect his Chinese speaking skills. ",
			},
			{
				img: leaf,
				name: "Case Study Three",
				nameCn: "案例三",
				school: "Field related mentorship",
				reviewCn:
					"我们的一些学生有着如此特别的热情,以至于他们需要外部导师。我们尽力满足这些愿望,让我们的每个学生都能全面了解他们所追求的行业和未来。我们的一名上海国际高中学生得到了法拉利 F1 车队领军人物以及 F3 世界杯冠军的指导,从而真正融入了赛车世界。我们相信,这样的案例能够让我们的学生成长到超越课堂所能提供的水平,并促进真正的个人成长。",
				review:
					"Some of our students have a passion so special that they require outside mentors.  We do our best to match these desires so that each of our students can have a full view of the industry and future that they are pursuing.  One of our Shanghai international high school students had a guided mentorship from a leading member of the Ferrari F1 team as well as a F3 world world cup winner in order to truly integrate into the world of racing. We believe cases like these enable our students to grow beyond what the class room has to offer and foster real personal growth. ",
			},
			{
				img: leaf,
				name: "Case Study Four",
				nameCn: "案例四",
				school: "Undergrad application",
				reviewCn:
					"在声音教育,我们相信我们的工作不是让我们的学生进入排名最高的大学,而是找到一个最适合我们学生需求的地方。一个典型的例子是我们来自中国瑞安小镇的 LGBTQ 学生。作为一个在中国面临歧视的人,我们帮助她撰写了高中研究论文,主题是财富、教育和文化如何影响社区对 LGBTQ 的接受度。她的 GPA 为 3.89,SAT 为 1450,来自中国的巴纳德 ED 申请池中处于较低分数,但她取得了成功，因为这所学校非常适合她自己的个人旅程,而她正是这样的类型。巴纳德正在寻找意志坚定、独立的女性。",
				review:
					"At Voices Education, we believe our job is not to get our students into the highest ranked college possible, but rather find a place which is best suited to the needs of our student. One prime example is our LGBTQ student from the small town of Ruian, China.  As someone who faced discrimination in China, we helped base her high school research paper on how wealth, education and culture affects a communitie’s acceptance of LGBTQ.  With a GPA of 3.89 and SAT of 1450, she fall on the lower score side of Barnard’s ED application pool in from China, and yet she was successful because the school was a great fit for her own personal journey, and she was exactly the type of strong minded, independent women Barnard was seeking. ",
			},
		],
		vip: [
			{
				titleCn: "学院/大学和文科",
				title: "College and University",
				descriptions: [
					{
						textCn:
							"为每个学生的课程作业、课外活动参与和学科探索制定详细的计划。",
						text: "Create a detailed plan for each student's coursework, extracurricular activity engagement, and academic subject exploration.",
					},
					{
						textCn:
							"指导学生完成暑期学校申请流程,使他们在申请大学时更具竞争力。",
						text: "Guide students through the summer school application process to make them more competitive when applying to colleges.",
					},
					{
						textCn: "提供学术充实课程。",
						text: "Provide academic enrichment courses.",
					},
					{
						textCn:
							"我们提供一对一咨询从 10 年级夏季开始,包括 2 次完整的夏季规划课程。",
						text: "We provide One on one consulting starting in the summer of 10th grade, with 2 full summer planning sessions.",
					},
					{
						textCn: "我们每月进行强制性签到/无限制的学生要求签到。",
						text: "We conduct mandatory Bi monthly check in/ and unlimited student requested check-ins.",
					},
					{
						textCn: "提供深入的教育方法、理解大学目标和大学准备支持。",
						text: "Provides in depth approach toward education and understanding college goals and college readiness support.",
					},
					{
						textCn:
							"我们帮助家长规划大学参观计划/对于无法旅行的家长,我们提供亲自参观的额外服务。",
						text: "We help parents plan for College visit planning/ for those parents who are unable to travel, we provide additional services for in person visits.",
					},
					{
						textCn: "我们每年对目标进行审查,并了解他们离目标有多近/多远。",
						text: "We provide a yearly review of goals to help understand how close/far they are from their goal",
					},
					{
						textCn: "我们帮助每个学生制定独特的个人项目计划。",
						text: "We help develop a personal project plan for each of our student that is unique to their journey ",
					},
					{
						textCn: "我们为所有注册学生提供考试建议、论文指导和面试准备。",
						text: "We provide test advising, essay guidance and interview preparation for all our enrolled student",
					},
					{
						textCn:
							"我们确保 48 小时内处理学生提交的所有作业,并由至少 2 名额外编辑进行申请审核,以确保清晰度。",
						text: "We ensure on 48 hr turnaround on all work submitted by our students and a application review by at least 2 additional editors to ensure clarity",
					},
				],
				price: "35,000",
				miscCn: "我们将每个申请年的学生人数限制为 20 名学生。",
				misc: "Limited to 20 students per application year",
			},
			{
				titleCn: "音乐联盟申请",
				title: "Music Consortium Application",
				descriptions: [
					{
						textCn:
							"为每个学生的课程作业、课外活动参与和学科探索制定详细的计划。",
						text: "Create a detailed plan for each student's coursework, extracurricular activity engagement, and academic subject exploration.",
					},
					{
						textCn:
							"指导学生完成暑期学校申请流程,使他们在申请大学时更具竞争力。",
						text: "Guide students through the summer school application process to make them more competitive when applying to colleges.",
					},
					{
						textCn: "提供学术充实课程和录音/实习时间。",
						text: "Provide academic enrichment courses and recording/ internship times.",
					},
					{
						textCn:
							"我们从 10 年级夏季开始提供一对一咨询,并提供 2 次完整的夏季规划课程。",
						text: "We provide One on one consulting starting in the summer of 10th grade, with 2 full summer planning sessions.",
					},
					{
						textCn: "我们每月进行强制性登记。",
						text: "We conduct mandatory Bi monthly check in.",
					},
					{
						textCn: "提供深入的教育方法、理解大学目标和大学准备支持。",
						text: "Provides in depth approach toward education and understanding college goals and college readiness support.",
					},
					{
						textCn: "我们为所有注册学生提供考试建议、论文指导和面试准备。",
						text: "We provide test advising, essay guidance and interview preparation for all our enrolled student.",
					},
					{
						textCn:
							"我们确保 48 小时内处理学生提交的所有作业,并由至少 2 名额外编辑进行申请审核,以确保清晰度。",
						text: "We ensure on 48 hr turnaround on all work submitted by our students and a application review by at least 2 additional editors to ensure clarity.",
					},
				],
				price: "35,000",
				miscCn: "我们将每个申请年的学生入学人数限制为 10 名学生。",
				misc: "Limited to 10 students per application year",
			},
			{
				titleCn: "美术学校申请 （仅限大三至大四）",
				title: "Fine Arts School Application (Jr year to Sr year only)",
				descriptions: [
					{
						textCn:
							"学生会与一位特定的导师相匹配,在整个申请周期中为他们提供帮助。",
						text: "Students are matched with a specific mentor that will help them throughout the application cycle.",
					},
					{
						textCn: "他们可以几乎同步或异步地审查材料。",
						text: "They can work virtually synchronous or asynchronous to review materials.",
					},
					{
						textCn:
							"导师将在申请的各个方面为您提供帮助,包括论文、简答题、简历、课外活动清单和艺术作品集。",
						text: "Mentors will help you on all parts of the application, including essays, short answers, resumes, extracurricular lists, and art portfolios.",
					},
					{
						textCn:
							"我们与学生合作开发作品集的各个方面,包括但不限于基本作品集和设计作品集。",
						text: "We work with students for all aspects of their portfolio development including but not limited to basic portfolio and design portfolio.",
					},
				],
				price: "18,000",
				miscCn: "我们将每个申请年的学生入学人数限制为 5 名学生。",
				misc: "Limited to 5 students per application year",
			},
		],
	},
];

export default data;
