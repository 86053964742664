import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import PopUp from "./PopUp";
import { Button } from "react-bootstrap";

export default function PackageCard(props) {
	const { title, content, subTitle, services } = props;
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => {
		setShowModal(false);
	};
	const handleShow = () => {
		setShowModal(true);
	};

	return (
		<div className="packageCard">
			<div>
				<p style={{ fontSize: "20px" }}>{title}</p>
				<p style={{ height: "25px" }}>{subTitle}</p>
				<ul>
					{content.map((el) => {
						return <li key={el.detail}>{el.detail}</li>;
					})}
				</ul>
			</div>
			<div>
				<Link
					onClick={handleShow}
					style={{ color: "white", textDecoration: "none" }}
				>
					<Button
						className="buttons"
						style={{ marginTop: "100px" }}
					>
						Learn More
					</Button>
				</Link>
			</div>
			<PopUp
				handleClose={handleClose}
				handleShow={handleShow}
				showModal={showModal}
				services={services}
			/>
		</div>
	);
}
