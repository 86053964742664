import { React } from "react";
import { Link } from "react-router-dom";
export default function Footer() {
	return (
		<div className="footer">
			<div style={{ margin: "auto" }}>
				<p>©COPYRIGHT VOICES-EDUCATION</p>
			</div>
		</div>
	);
}
