import { React } from "react";

import { Modal, Button } from "react-bootstrap";

export default function PopUp(props) {
	const { showModal, handleClose, services } = props;
	return (
		<div>
			<Modal show={showModal} onHide={handleClose} className="popup">
				<Modal.Header closeButton>
					<Modal.Title>Services</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{services.map((service) => {
						return (
							<div key={service.title}>
								<p style={{ fontSize: "20px", fontWeight: "bold" }}>
									{service.title}{" "}
								</p>
								<p style={{ fontWeight: "bold" }}>{service.name}</p>
								<p>{service.description}</p>
								<p>
									<strong>$TBD</strong>
								</p>
							</div>
						);
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
