import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import data from "../data";
import emailjs from "emailjs-com"; // import emailjs library
import { LanguageContext } from "../controller/LanguageContext";

export default function Contact(props) {
	const { isEn } = useContext(LanguageContext);
	const program = data[0].packages;

	const [emailData, setEmailData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		program: "",
		currentGrade: "",
		majorAndGoals: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEmailData({ ...emailData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// send email using EmailJS
		if (
			emailData.firstName.length > 0 &&
			emailData.lastName.length > 0 &&
			emailData.email.length > 0 &&
			emailData.program.length > 0
		) {
			emailjs
				.send(
					process.env.REACT_APP_EMAILJS_SERVICE_ID, // replace with your service ID
					"template_9blhr7y", // replace with your template ID
					emailData,
					process.env.REACT_APP_EMAILJS_USER_ID, // replace with your user ID
				)
				.then(
					(response) => {
						console.log("Email sent successfully!", response);
					},
					(error) => {
						console.error("Failed to send email:", error);
					},
				);

			emailjs
				.send(
					process.env.REACT_APP_EMAILJS_SERVICE_ID, // replace with your service ID
					"template_bl7e849", // replace with your template ID
					emailData,
					process.env.REACT_APP_EMAILJS_USER_ID, // replace with your user ID
				)
				.then(
					(response) => {
						alert("Email sent successfully!", response);
					},
					(error) => {
						alert("Failed to send email:", error);
					},
				);
		} else {
			alert("all fields are required");
		}
	};

	return (
		<div className="fadeIn" style={{ minHeight: "85vh" }}>
			<div className="contact-section">
				<h3>SCHEDULE A DISCOERY CALL</h3>
				<p>LET BEGIN THE JOURNEY TO THE RIGHT DESTINATION FOR YOU</p>
			</div>
			<div className="contact-section">
				<h3>{isEn ? "联系方式" : "Contact Information"}</h3>
				<Form style={{ width: "100%" }}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>{isEn ? "名字" : "First Name"}</Form.Label>
						<Form.Control
							type="text"
							name="firstName"
							value={emailData.firstName}
							onChange={handleInputChange}
							required
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>{isEn ? "姓氏" : "First Name"}</Form.Label>
						<Form.Control
							type="text"
							name="lastName"
							value={emailData.lastName}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>{isEn ? "电子邮件" : "Email Address"}</Form.Label>
						<Form.Control
							required
							type="email"
							placeholder="name@example.com"
							name="email"
							value={emailData.email}
							onChange={handleInputChange}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>
							{isEn
								? "您有兴趣与我们讨论哪个项目"
								: "Which Program are you interested in"}
						</Form.Label>
						<Form.Select
							name="program"
							value={emailData.program}
							onChange={handleInputChange}
						>
							<option value="">Select a program</option>
							{program.map((el, index) => {
								return (
									<option key={index} value={emailData.title}>
										{el.title}
									</option>
								);
							})}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>{isEn ? "当前的等级" : "Current Grade"}</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							name="currentGrade"
							value={emailData.currentGrade}
							onChange={handleInputChange}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Label>{isEn ? "意向专业" : "Intended Major"}</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							name="majorAndGoals"
							value={emailData.majorAndGoals}
							onChange={handleInputChange}
						/>
					</Form.Group>
				</Form>
			</div>

			<Button onClick={handleSubmit} className="buttons">
				Submit
			</Button>
		</div>
	);
}
