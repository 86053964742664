import React, { useRef, useState, useContext, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import CaseStudyItem from "./CaseStudyItem";
import { LanguageContext } from "../controller/LanguageContext";

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

export default function SwiperNew(props) {
    const { caseStudy } = props;
    const { isEn } = useContext(LanguageContext);

    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}


                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                    '@2.00': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    }
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                {caseStudy.map((el, index) => {
                    return <SwiperSlide style={{ width: '50%' }} key={index}>
                        <CaseStudyItem
                            key={el.name}
                            name={isEn ? el.nameCn : el.name}
                            school={el.school}
                            review={isEn ? el.reviewCn : el.review}
                            img={el.img}
                        />
                    </SwiperSlide>
                })}

            </Swiper>
        </>
    );
}
