import React, { useContext } from "react";
import { LanguageContext } from "../controller/LanguageContext";

export default function Team(props) {
	const { name, img, content, textColor } = props;
	const borderColor = `1px solid ${textColor}`;
	const { isEn } = useContext(LanguageContext);

	return (
		<div>
			<p className="meetTeam">MEET {name}</p>
			<div className="team" style={{ display: "flex", marginTop: "25px" }}>
				<div className="team-left" style={{ borderRight: borderColor }}>
					<img src={img} alt={name} />
				</div>
				<div className="team-right" style={{ display: "flex" }}>
					<ul>
						{content.map((el) => {
							return <li key={el.detail}>{isEn ? el.detailCn : el.detail}</li>;
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}
